<template>
  <div>
    <back />
    <div class="container">
      <h3>供应商基本信息：</h3>
      <el-row class="row" type="flex" align="middle">
        <el-col :span="3">
          <div class="label">供应商名称：</div>
        </el-col>
        <el-col :span="7">
          <div class="value">{{ detail.supplierName }}</div>
        </el-col>
        <el-col :span="3">
          <div class="label">联系人：</div>
        </el-col>
        <el-col :span="7">
          <div class="value">{{ detail.contactPerson }}</div>
        </el-col>
        <el-col :span="3">
          <div class="label">联系电话：</div>
        </el-col>
        <el-col :span="7">
          <div class="value">{{ detail.userPhone }}</div>
        </el-col>
      </el-row>

      <el-divider></el-divider>

      <h3>中标信息：</h3>
      <el-row class="row" type="flex" align="middle">
        <el-col :span="3">
          <div class="label">资格类型： </div>
        </el-col>
        <el-col :span="7">
          <div class="value">{{ detail.eligibleType == 1 ? '平台资格' : '独标资格' }}</div>
        </el-col>
        <el-col :span="3">
          <div class="label">品牌：</div>
        </el-col>
        <el-col :span="7">
          <div class="value" :title="detail.brand">{{ detail.brand }}</div>
        </el-col>
        <el-col :span="3">
          <div class="label"> 中标有效期：</div>
        </el-col>
        <el-col :span="7">
          <div class="value">{{ detail.effectiveStartTime }}至{{ detail.effectiveEndTime }}</div>
        </el-col>
      </el-row>

      <el-row class="row" type="flex" align="middle">
        <el-col :span="3">
          <div class="label">标书名称：</div>
        </el-col>
        <el-col :span="7">
          <div class="value" :title="detail.tendersName">{{ detail.tendersName }}</div>
        </el-col>
        <el-col :span="3">
          <div class="label">分类名称：</div>
        </el-col>
        <el-col :span="7">
          <div class="value">{{ detail.typeName }}</div>
        </el-col>
        <el-col :span="3">
          <div class="label">中标单价：</div>
        </el-col>
        <el-col :span="7">
          <div class="value">{{ detail.tenderProductPrice }}</div>
        </el-col>
      </el-row>

      <el-row class="row" type="flex" align="middle">
        <el-col :span="3">
          <div class="label">标号：</div>
        </el-col>
        <el-col :span="7">
          <div class="value" :title="detail.tendersLabel">{{ detail.tendersLabel }}</div>
        </el-col>
        <el-col :span="3">
          <div class="label">中标商品：</div>
        </el-col>
        <el-col :span="7">
          <div class="value">{{ detail.productName }}</div>
        </el-col>
        <el-col :span="3">
          <div :class="detail.warnStatus == 0 ? 'label' : 'label red'" class="label"> 中标预警：</div>
        </el-col>
        <el-col :span="7">
          <div :class="detail.warnStatus == 0 ? 'value' : 'value red'">{{ detail.warnStatus == 0 ? '否' : '是' }}</div>
        </el-col>
      </el-row>

      <el-row class="row" type="flex" align="middle">
        <el-col :span="3">
          <div class="label">应标金额：</div>
        </el-col>
        <el-col :span="7">
          <div class="value">{{ detail.tenderProductPrice }}</div>
        </el-col>
        <el-col :span="3">
          <div class="label">规格：</div>
        </el-col>
        <el-col :span="7">
          <div class="value">{{ detail.specs }}</div>
        </el-col>
        <el-col :span="3">
          <div class="label"> 状态：</div>
        </el-col>
        <el-col :span="7">
          <div class="value">{{ detail.warnStatus == 2 ? '取消预警' : detail.warnStatus == 3 ? '取消中标资格' : '' }}</div>
        </el-col>
      </el-row>

      <el-row class="row" type="flex" align="middle" v-if="detail.warnStatus == 3" style="color: red;">
        <el-col :span="3">
          <div class="label">不通过原因：</div>
        </el-col>
        <el-col :span="7">
          <div class="value">{{ detail.text }}</div>
        </el-col>
        <el-col :span="20"></el-col>
      </el-row>

      <el-row class="row" type="flex" align="middle" v-if="detail.warnStatus == 2 || detail.warnStatus == 3">
      </el-row>

      <div style="text-align: center;" v-if="detail.warnStatus == 1">
        <el-button type="primary" @click="handlePass">通过</el-button>
        <el-button @click="handleNoPass">不通过</el-button>
      </div>
    </div>

    <el-dialog title="审核不通过" :visible.sync="submitVisible" :before-close="onCancel">
      <el-form ref="examineForm" class="examineForm" :rules="rules" :model="examineForm" label-position="left" label-width="130px">
        <el-form-item label="不通过原因" prop="loseContent">
          <el-input type="textarea" v-model="examineForm.loseContent" :autosize="{ minRows: 4, maxRows: 15 }"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('examineForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "productDetail",
  data () {
    return {
      detail: {},
      applicationId: "",
      statusArr: ["待审核", "审核通过", "审核不通过"],
      submitVisible: false,
      examineForm: {
        loseContent: "",
      },
      rules: {
        loseContent: [
          { required: true, message: "请输入不通过原因", trigger: "submit" },
        ],
      },
    };
  },
  created () {
    this.applicationId = this.$route.query.id;
    this.list_info_new();
  },
  methods: {
    list_info_new () {
      this.$axios
        .get(this.$api.list_info_new, {
          params: {
            biddingProductId: this.applicationId,
          },
        })
        .then((res) => {
          this.detail = res.data.result;
        })
        .catch(() => { });
    },
    //  通过
    handlePass () { 
      this.$confirm("确定该中标单价？, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .post(this.$api.warning_review, {
              biddingProductId: this.applicationId,
              warnStatus: "2",
            })
            .then((res) => {
              if (res.data.code == 100) {
                this.$message({
                  type: "success",
                  message: "审核成功!",
                });
                this.$router.back();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消审核",
          });
        });
    },
    // 不通过
    handleNoPass () {
      this.submitVisible = true;
    },
    // 提交表单
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        console.log({
          biddingProductId: this.applicationId,
          text: this.examineForm.loseContent,
        });
        if (valid) {
          this.$axios
            .post(this.$api.warning_review, {
              biddingProductId: this.applicationId,
              text: this.examineForm.loseContent,
              warnStatus: "3",
            })
            .then((res) => {
              this.$message.success(res.data.desc);
              this.onCancel();
              this.$router.back();
            })
            .catch((err) => {
              this.$message.error(err.data.desc);
            });
        } else {
          this.$message.error("提交失败");
          return false;
        }
      });
    },
    // 关闭对话框
    onCancel () {
      this.submitVisible = false;
      this.examineForm = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 30px 30px 0;
  //   border-radius: 6px;
  //   border: 1px solid #e2e2e2;
  color: #1a1a1a;

  .red {
    color: red;
  }

  .row {
    margin: 30px 0px;

    .label {
      text-align: right;
    }

    //  .value {
    //    height: 46px;
    //    line-height: 46px;
    //    padding: 0 20px;
    //    border-radius: 10px;
    //    border: 1px solid #1a03100e;
    //    margin-left: 30px;
    //    white-space: nowrap;
    //    overflow: hidden;
    //    text-overflow: ellipsis;
    //  }

    .text-red {
      color: #eb2500;
    }
  }
}
</style>